import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/digital-garden-template.js";
import { CodeBlock } from '../../../src/components/DigitalGarden';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`title: "Heroku"`}</p>



    <h2 {...{
      "id": "heroku",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#heroku",
        "aria-label": "heroku permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Heroku`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#export-database-from-heroku"
        }}>{`Export database from Heroku`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#restore-dump-to-local-database"
        }}>{`Restore dump to local database`}</a></li>
    </ol>
    <h3 {...{
      "id": "export-database-from-heroku",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#export-database-from-heroku",
        "aria-label": "export database from heroku permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Export database from Heroku`}</h3>
    <ul>
      <li parentName="ul">{`Create backup`}</li>
    </ul>
    <CodeBlock mdxType="CodeBlock">
      {`heroku pg:backups:capture --app <heroku_app_name>`}
    </CodeBlock>
    <ul>
      <li parentName="ul">{`Download backup`}</li>
    </ul>
    <CodeBlock mdxType="CodeBlock">
      {`heroku pg:backups:download --app <heroku_app_name>`}
    </CodeBlock>
    <hr></hr>
    <h3 {...{
      "id": "restore-dump-to-local-database",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#restore-dump-to-local-database",
        "aria-label": "restore dump to local database permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Restore dump to local database`}</h3>
    <CodeBlock mdxType="CodeBlock">
      {`pg_restore --verbose --clean --no-acl --no-owner -h localhost -U <postgres_username> -d <database> latest.dump`}
    </CodeBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      